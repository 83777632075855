@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

body2{
background: hsla(223, 100%, 97%, 1);
background: radial-gradient(circle, hsla(223, 100%, 97%, 1) 10%, hsla(0, 0%, 100%, 1) 100%);
background: -webkit-radial-gradient(circle, hsla(223, 100%, 97%, 1) 10%, hsla(0, 0%, 100%, 1) 100%);
filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#F1F5FF", endColorstr="#FFFFFF", GradientType=1 );
}
html, body {
  height: 100%;
}
/* canvas{
  width: 100%!important;
   height: 100%!important;
} */
/* @media (min-width: 1200px){
  .setting-nav, body, main{
    min-height: calc(100vh - 180px)
  }
} */
@-webkit-keyframes loader {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes loader {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.loader-bar {
  -webkit-animation: loader 2s infinite;
          animation: loader 2s infinite;
}



.card-wrap {
  display: none;
}

@media (min-width: 768px) {
  .card-wrap {
    display: block;
  }
}
@media (max-width: 768px) {
  .card-wrap.active {
    display: block;
  }
}

.gradient-bg {
  background: linear-gradient(to top right, rgb(237 43 83), rgb(238 65 46));
}

.skeleton-loader::after {
  content: " ";
  box-shadow: 0 0 50px 9px rgb(93, 93, 93);
  position: absolute;
  top: 0;
  left: -100%;
  height: 100%; 
  animation: load 1.5s infinite;
}
@keyframes load {
  0%{ left: -100%}
  100%{ left: 150%}
}

.fade-in{
  opacity: 0;
  animation: fadeIn 1s ease-in-out;
  animation-fill-mode: forwards;
}
@keyframes fadeIn {
  0%{ opacity: 0}
  100%{ opacity: 1}
}
